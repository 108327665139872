import { CSRF_TOKEN_HEADER } from 'api/consts';

export type FetchResult<T> = { success: true; value: T } | { success: false; reason: string };

type SuccessResponse = {
  <T>(responseData?: T): FetchResult<T> | undefined;
  (responseData: undefined): FetchResult<undefined>;
};

export const successResponse: SuccessResponse = (responseData = undefined) => ({
  success: true as const,
  value: responseData,
});

export const fetchWrapper = async (url: string, requestInit: RequestInit) => {
  const response = await fetch(url, requestInit);
  const hasContent = response.headers.get('Content-Length') !== '0';

  if (response.status === 403 || response.status === 422) {
    if (hasContent) {
      const data = await response.json();
      return { success: false, reason: data.detail as string };
    } else {
      return { success: false, reason: 'Server responded with no detail' };
    }
  }

  if (!response.ok) {
    throw new Error(`${requestInit.method} request to ${url} failed with ${response.status}`);
  }

  // TODO: implement foolproof hasContent check
  // current implementation sometimes fails when the response is empty
  try {
    return hasContent ? await response.json() : null;
  } catch (e) {
    return null;
  }
};

export const fetchOptions = <T = Record<string, unknown>>(
  method: string,
  data?: T,
): RequestInit => {
  const options: RequestInit = {
    method,
    credentials: 'include',
    headers: {
      ...CSRF_TOKEN_HEADER,
      'Content-Type': 'application/json',
    },
  };

  if (data !== undefined) {
    options.body = JSON.stringify(data);
  }

  return options;
};
