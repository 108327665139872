import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

import { addQueryString, deleteNil, makeQueryString } from 'utils';

interface optionsInterface {
  method?: 'replace' | 'push';
  shallow?: boolean;
}
type queryParamsType = Record<string, string | number | boolean>;

/**
 *  Updates query params whenever passed queryParams doesn't match the query of the router.
 */
const useUpdateQueryParams = (queryParams: queryParamsType, options: optionsInterface = {}) => {
  const { method = 'replace', shallow = true } = options;
  const router = useRouter();

  const [url] = router.asPath.split('?');
  const queryParamsString = makeQueryString(deleteNil(queryParams));

  const newPath = addQueryString(url, queryParamsString);
  const oldPath = useRef(router.asPath);

  useEffect(() => {
    if (oldPath.current !== newPath) {
      router[method]({ pathname: router.pathname, query: queryParams }, undefined, {
        shallow,
      });

      oldPath.current = newPath;
    }
  }, [router, url, oldPath, newPath, method, queryParams, shallow]);
};

export default useUpdateQueryParams;
