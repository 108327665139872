import { CLOSE_KEYS } from 'consts';

import useKeyDown from './useKeyDown';

const useEscape = (callback: (e: KeyboardEvent) => void) => {
  useKeyDown(e => {
    if (CLOSE_KEYS.includes(e.key)) {
      callback(e);
    }
  });
};

export default useEscape;
