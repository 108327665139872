import { useQuery, useQueryClient } from '@tanstack/react-query';

import { API_URL, CSRF_TOKEN_HEADER, ENDPOINTS } from 'api/consts';
import {
  PagedUserSurveySchema,
  SubmitUserSurvey,
  UpdateUserSurvey,
  UserSurveySchema,
} from 'types/Changemkr';

export type UserSurveysContext = {
  isLoading: boolean;
  userSurveys: UserSurveySchema[];
  update: (
    id: number,
    changes: UpdateUserSurvey,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  create: (
    request: SubmitUserSurvey,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
};

export default function useUserSurveys(): UserSurveysContext {
  const queryClient = useQueryClient();
  const { isLoading, data: userSurveys } = useQuery(['user-surveys'], async () => {
    const response = await fetch(API_URL + ENDPOINTS['user-survey']['list'], {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...CSRF_TOKEN_HEADER,
      },
    });

    if (!response.ok) {
      throw new Error(`${ENDPOINTS['user-survey']['list']} request failed with ${response.status}`);
    }

    return ((await response.json()) as PagedUserSurveySchema).items;
  });

  const update = async (id: number, changes: UpdateUserSurvey) => {
    const response = await fetch(API_URL + ENDPOINTS['user-survey']['update'](id), {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify(changes),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Not found or unprocessable entity
    if (response.status === 404 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(
        `${ENDPOINTS['user-survey']['update'](id)} request failed with ${response.status}`,
      );
    }

    const newUserSurvey = await response.json();

    queryClient.setQueryData<UserSurveySchema[]>(
      ['user-surveys'],
      (oldUserSurveys: UserSurveySchema[] | undefined) => {
        if (oldUserSurveys === undefined) {
          return;
        }

        const newUserSurveys = structuredClone(oldUserSurveys);
        const newUserSurveyIndex = newUserSurveys.findIndex(
          userSurvey => userSurvey.id === newUserSurvey.id,
        );
        newUserSurveys[newUserSurveyIndex] = newUserSurvey;

        return newUserSurveys;
      },
    );

    return { success: true } as const;
  };

  const create = async (request: SubmitUserSurvey) => {
    const response = await fetch(API_URL + ENDPOINTS['user-survey']['create'], {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(request),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Unprocessable entity
    if (response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(
        `${ENDPOINTS['user-survey']['create']} request failed with ${response.status}`,
      );
    }

    const newUserSurvey = await response.json();

    queryClient.setQueryData<UserSurveySchema[]>(
      ['user-surveys'],
      (oldUserSurveys: UserSurveySchema[] | undefined) => {
        if (oldUserSurveys === undefined) {
          return;
        }

        const newUserSurveys = structuredClone(oldUserSurveys);
        const newUserSurveyIndex = newUserSurveys.findIndex(
          userSurvey => userSurvey.id === newUserSurvey.id,
        );

        if (newUserSurveyIndex !== -1) {
          newUserSurveys[newUserSurveyIndex] = newUserSurvey;
        } else {
          newUserSurveys.push(newUserSurvey);
        }

        return newUserSurveys;
      },
    );

    return { success: true } as const;
  };

  return { isLoading, userSurveys: userSurveys ?? [], update, create };
}
