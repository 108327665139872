import { useQuery } from '@tanstack/react-query';

import { API_URL, CSRF_TOKEN_HEADER, ENDPOINTS } from 'api/consts';
import { SurveySchema } from 'types/Changemkr';

export default function useSurveys() {
  return useQuery<SurveySchema[]>(
    ['surveys'],
    async () => {
      const response = await fetch(API_URL + ENDPOINTS['survey']['list'], {
        method: 'GET',
        credentials: 'include',
        headers: {
          ...CSRF_TOKEN_HEADER,
        },
      });

      if (!response.ok) {
        throw new Error(`${ENDPOINTS['survey']['list']} request failed with ${response.status}`);
      }

      return (await response.json()) as SurveySchema[];
    },
    {},
  );
}
