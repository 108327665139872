import { useMemo } from 'react';

import { UpdateUserSurvey } from 'types/Changemkr';
import useUserSurveys from './useUserSurveys';

export default function useUserSurvey(orgSurveyId: number) {
  const { userSurveys, update } = useUserSurveys();
  const userSurvey = useMemo(
    () => userSurveys.find(({ org_survey_id }) => org_survey_id === orgSurveyId),
    [userSurveys, orgSurveyId],
  );

  return {
    userSurvey,
    update: (changes: UpdateUserSurvey) => {
      if (userSurvey?.id == null) {
        throw new Error(`Could not find user survey with org survey id ${orgSurveyId}`);
      }
      return update(userSurvey.id, changes);
    },
  };
}
