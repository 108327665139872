import { useMemo } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { API_URL, CSRF_TOKEN_HEADER, ENDPOINTS } from 'api/consts';
import {
  CommentSchema,
  CreateCommentSchema,
  PlanSchema,
  TaskDetailSchema,
  UpdateCommentSchema,
  UpdateTaskSchema,
} from 'types/Changemkr';
import useMembership from './useMembership';
import usePlan from './usePlan';

export interface UseTask {
  isLoading: boolean;
  task?: TaskDetailSchema;
  updateTask: (
    changes: UpdateTaskSchema,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  createComment: (
    comment: CreateCommentSchema,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  updateComment: (
    id: number,
    comment: UpdateCommentSchema,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
  deleteComment: (
    id: number,
  ) => Promise<{ success: true; reason?: undefined } | { success: false; reason: string }>;
}

const useTask = (id: number): UseTask => {
  const queryClient = useQueryClient();
  const { membership } = useMembership();
  // while loading membership we default to plan 0 (as you cannot call the hook conditionally)
  const { plan, updateTask } = usePlan(membership?.organization.id ?? 0);
  const { isLoading, data: taskDetail } = useQuery(['task', id], async () => {
    const response = await fetch(API_URL + ENDPOINTS.tasks['retrieve'](id), {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...CSRF_TOKEN_HEADER,
      },
    });

    // Unauthorized
    if (response.status === 401) {
      return;
    }

    if (!response.ok) {
      throw new Error(
        `${API_URL + ENDPOINTS.tasks['retrieve'](id)} request failed with ${response.status}`,
      );
    }

    return (await response.json()) as TaskDetailSchema;
  });
  const planTask = useMemo(() => plan?.tasks?.find(task => task.id === id), [id, plan?.tasks]);
  // TODO: This is wrong, but would require a bit more work than I am willing
  // to do to change. plan tasks != task details
  const task = (taskDetail ?? planTask) as TaskDetailSchema | undefined;

  if (task == null && !isLoading) {
    throw new Error(`Could not find task with id ${id}`);
  }

  const createComment = async (comment: CreateCommentSchema) => {
    const response = await fetch(API_URL + ENDPOINTS.tasks['create-comment'](id), {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(comment),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Unauthorized or Unprocessable Entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(
        `${ENDPOINTS.tasks['create-comment'](id)} request failed with ${response.status}`,
      );
    }

    const taskData = (await response.json()) as TaskDetailSchema;

    queryClient.setQueryData<TaskDetailSchema>(['task', id], taskData);
    queryClient.setQueryData<PlanSchema>(['plan', task!.plan_id], oldPlan => {
      if (oldPlan === undefined || typeof oldPlan.tasks === 'undefined') {
        return;
      }

      const newPlan = structuredClone(oldPlan);
      const taskIndex = newPlan.tasks?.findIndex(task => task.id === taskData.id);

      if (taskIndex === undefined || taskIndex === -1) {
        throw new Error(
          `Could not find task with id ${taskData.id} in plan with id ${task!.plan_id}`,
        );
      }

      newPlan.tasks![taskIndex] = taskData;

      return newPlan;
    });

    return { success: true } as const;
  };

  const updateComment = async (commentId: number, comment: UpdateCommentSchema) => {
    const response = await fetch(API_URL + ENDPOINTS.tasks['update-comment'](commentId), {
      method: 'PATCH',
      credentials: 'include',
      body: JSON.stringify(comment),
      headers: {
        ...CSRF_TOKEN_HEADER,
        'Content-Type': 'application/json',
      },
    });

    // Unauthorized or Unprocessable Entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(
        `${ENDPOINTS.tasks['update-comment'](commentId)} request failed with ${response.status}`,
      );
    }

    const commentData = (await response.json()) as CommentSchema;

    queryClient.setQueryData<TaskDetailSchema>(['task', id], oldTask => {
      if (oldTask === undefined) {
        return;
      }

      const newTask = structuredClone(oldTask);
      const commentIndex = newTask.comments?.findIndex(comment => comment.id === commentData.id);

      if (commentIndex === undefined || commentIndex === -1) {
        throw new Error(`Could not find comment with id ${commentData.id} in task with id ${id}`);
      }

      newTask.comments![commentIndex] = commentData;

      return newTask;
    });

    return { success: true } as const;
  };

  const deleteComment = async (commentId: number) => {
    const response = await fetch(API_URL + ENDPOINTS.tasks['delete-comment'](commentId), {
      method: 'DELETE',
      credentials: 'include',
      headers: {
        ...CSRF_TOKEN_HEADER,
      },
    });

    // Unauthorized or Unprocessable Entity
    if (response.status === 401 || response.status === 422) {
      return { success: false, reason: (await response.json()).detail as string } as const;
    }

    if (!response.ok) {
      throw new Error(
        `${ENDPOINTS.tasks['delete-comment'](commentId)} request failed with ${response.status}`,
      );
    }

    queryClient.setQueryData<TaskDetailSchema>(['task', id], oldTask => {
      if (oldTask === undefined) {
        return;
      }

      const newTask = structuredClone(oldTask);
      const commentIndex = newTask.comments?.findIndex(comment => comment.id === commentId);

      if (commentIndex === undefined || commentIndex === -1) {
        throw new Error(`Could not find comment with id ${commentId} in task with id ${id}`);
      }

      newTask.comments!.splice(commentIndex, 1);

      return newTask;
    });

    return { success: true } as const;
  };

  return {
    isLoading,
    task,
    updateTask: changes => {
      if (task?.id == null) {
        throw new Error(`Could not find task with id ${id}`);
      }
      return updateTask(task.id, changes);
    },
    createComment,
    updateComment,
    deleteComment,
  };
};

export default useTask;
