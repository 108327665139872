import { useQuery, useQueryClient } from '@tanstack/react-query';

import { API_URL, ENDPOINTS } from 'api/consts';
import {
  GroupSchema,
  MemberSchema,
  MembershipSchema,
  OrgMemberSchema,
  UpdateGroupMemberSchema,
  UserInviteSchema,
  UserSchema,
} from 'types/Changemkr';
import { fetchOptions, FetchResult, fetchWrapper, successResponse } from 'utils/fetchUtils';
import preventStructuralSharing from 'utils/preventStructuralSharing';
import useMembership from './useMembership';

export type OrgMembershipContext = (
  | {
      isLoading: false;
      membership: Readonly<MembershipSchema>;
      orgMembership: Readonly<OrgMemberSchema>;
    }
  | { isLoading: true; membership?: undefined; orgMembership?: undefined }
) & {
  isError: boolean;
  retrieve: () => Promise<FetchResult<OrgMemberSchema>>;
  updateGroupMember: (data: UpdateGroupMemberSchema) => Promise<FetchResult<MemberSchema>>;
  deleteGroup: (groupId: number) => Promise<FetchResult<undefined>>;
  updateGroupName: (groupId: number, name: string) => Promise<FetchResult<GroupSchema>>;
  createGroup: (name: string) => Promise<FetchResult<GroupSchema>>;
  createInvite: (userInvite: UserInviteSchema) => Promise<FetchResult<UserInviteSchema>>;
  deleteInvite: (userId: number) => Promise<FetchResult<undefined>>;
  deleteMember: (userId: number) => Promise<FetchResult<undefined>>;
};

export default function useOrgMembership(): OrgMembershipContext {
  const queryClient = useQueryClient();
  const { isLoading: membershipIsLoading, membership } = useMembership();

  const {
    isLoading,
    isError,
    data: orgMembership,
  } = useQuery(
    ['orgMembership', membership?.organization.id], // add organization id to the key to refetch when it changes
    async () => {
      if (!membership) {
        throw new Error('User has no membership');
      }

      const responseData = await fetchWrapper(
        API_URL + ENDPOINTS.organization.retrieve(membership.organization.id),
        fetchOptions('GET'),
      );

      return responseData as OrgMemberSchema;
    },
    {
      enabled: !membershipIsLoading, // only fetch orgMembership data when member data is not loading
      retry: false,
      structuralSharing: preventStructuralSharing,
    },
  );

  const id = membership!.organization.id;
  if (!isLoading && membership == null) {
    throw new Error(`Could not find membership containing organization with id ${id}`);
  }

  const getOrgMembership = () => {
    return queryClient.getQueryData(['orgMembership', id]) as OrgMemberSchema;
  };

  const setOrgMembership = (data: OrgMemberSchema) => {
    queryClient.setQueryData(['orgMembership', id], structuredClone(data));
  };

  const updateGroupMember = async (data: UpdateGroupMemberSchema) => {
    const responseData = await fetchWrapper(
      API_URL + ENDPOINTS.organization['update-member'](id),
      fetchOptions('PATCH', data),
    );

    // update member data in query cache
    const updatedMember = responseData as MemberSchema;
    const newOrgMembership = getOrgMembership();
    newOrgMembership.members = newOrgMembership.members.map(member =>
      member.user.id === updatedMember.user.id ? updatedMember : member,
    );
    setOrgMembership(newOrgMembership);

    return successResponse(responseData);
  };

  const deleteGroup = async (groupId: number) => {
    await fetchWrapper(
      API_URL + ENDPOINTS.organization['delete-group'](id, groupId),
      fetchOptions('DELETE'),
    );

    // remove deleted group from query cache
    const newOrgMembership = getOrgMembership();
    newOrgMembership.groups = newOrgMembership.groups.filter(group => group.id !== groupId);
    newOrgMembership.members.forEach(member => {
      member.groups = member.groups.filter(group => group.id !== groupId);
    });
    setOrgMembership(newOrgMembership);

    return successResponse();
  };

  const updateGroupName = async (groupId: number, name: string) => {
    const responseData = await fetchWrapper(
      API_URL + ENDPOINTS.organization['update-group'](id, groupId),
      fetchOptions('PATCH', { name }),
    );

    // update group name in query cache
    const updatedGroup: GroupSchema = responseData;
    const newOrgMembership = getOrgMembership();
    newOrgMembership.groups = newOrgMembership.groups.map(group =>
      group.id === groupId ? updatedGroup : group,
    );
    newOrgMembership.members.forEach(member => {
      member.groups = member.groups.map(group => (group.id === groupId ? updatedGroup : group));
    });
    setOrgMembership(newOrgMembership);

    return successResponse(responseData);
  };

  const createGroup = async (name: string) => {
    const responseData = await fetchWrapper(
      API_URL + ENDPOINTS.organization['create-group'](id),
      fetchOptions('POST', { name }),
    );

    // add new group to query cache
    const newGroup: GroupSchema = responseData;
    const newOrgMembership = getOrgMembership();
    newOrgMembership.groups.push(newGroup);
    setOrgMembership(newOrgMembership);

    return successResponse(responseData);
  };

  const createInvite = async (userInvite: UserInviteSchema) => {
    const responseData = await fetchWrapper(
      API_URL + ENDPOINTS.organization['create-invite'](id),
      fetchOptions('POST', userInvite),
    );

    // add new invite to query cache
    const newUser: Omit<UserSchema, 'memberships'> = responseData;
    const groupId = userInvite.groups.at(0);
    const newOrgMembership = getOrgMembership();
    const newInvite: MemberSchema = {
      user: newUser,
      role: userInvite.membership.role!,
      title: userInvite.membership.title!,
      groups: groupId
        ? [{ id: groupId, name: newOrgMembership.groups.find(group => group.id === groupId)!.name }]
        : [],
    };
    newOrgMembership.members.push(newInvite);
    setOrgMembership(newOrgMembership);

    return successResponse(responseData);
  };

  const deleteInvite = async (userId: number) => {
    await fetchWrapper(
      API_URL + ENDPOINTS.organization['delete-invite'](id, userId),
      fetchOptions('DELETE'),
    );

    await queryClient.invalidateQueries(['orgMembership', id]);

    return successResponse();
  };

  const deleteMember = async (userId: number) => {
    await fetchWrapper(
      API_URL + ENDPOINTS.organization['delete-user'](id, userId),
      fetchOptions('DELETE'),
    );

    await queryClient.invalidateQueries(['orgMembership', id]);

    return successResponse();
  };

  return {
    isLoading,
    orgMembership,
    isError,
    membership,
    updateGroupMember,
    deleteGroup,
    createGroup,
    createInvite,
    deleteInvite,
    deleteMember,
    updateGroupName,
  } as OrgMembershipContext;
}
