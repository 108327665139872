import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';

import { API_URL, CSRF_TOKEN_HEADER, ENDPOINTS } from 'api/consts';
import useMembership from 'hooks/useMembership';
import { PlanStatus, PretestSchema } from 'types/Changemkr';

export type PretestContext =
  | {
      isLoading: false;
      status: PlanStatus;
      pretest: PretestSchema;
    }
  | { isLoading: true; status?: undefined; pretest?: undefined };

export default function usePretest(): PretestContext {
  const { isLoading: membershipIsLoading, membership } = useMembership();
  const { isLoading: pretestIsLoading, data: pretest } = useQuery(['pretest'], async () => {
    const response = await fetch(API_URL + ENDPOINTS.organization['pre-test'], {
      method: 'GET',
      credentials: 'include',
      headers: {
        ...CSRF_TOKEN_HEADER,
      },
    });

    // Forbidden
    if (response.status === 403) {
      return;
    }

    if (!response.ok) {
      throw new Error(
        `${ENDPOINTS.organization['pre-test']} request failed with ${response.status}`,
      );
    }

    return (await response.json()) as PretestSchema;
  });

  const status = useMemo<PlanStatus>(() => {
    if (membership?.organization.info == null) {
      return 'TODO';
    }

    if (
      Object.values<undefined | unknown>(membership.organization.info).every(
        value => value !== undefined,
      )
    ) {
      return 'COMPLETED';
    }

    return 'ONGOING';
  }, [membership]);

  return {
    isLoading: pretestIsLoading || membershipIsLoading,
    status,
    pretest,
  } as PretestContext;
}
