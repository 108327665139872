import { useEffect } from 'react';

const useKeyDown: (func: (e: KeyboardEvent) => void) => void = func => {
  useEffect(() => {
    window.addEventListener('keydown', func);

    return () => window.removeEventListener('keydown', func);
  }, [func]);
};

export default useKeyDown;
